import * as React from "react";
/**
 * The underlying DOM element which is rendered by this component.
 */
const Root = "a";
interface Props extends React.ComponentProps<typeof Root> {
  as?: React.ComponentType<React.ComponentProps<typeof Root>>;
  icon?: React.ReactNode;
  label: React.ReactNode;
  context?: React.ReactNode;
}
function SearchBoxListItem(props: Props, ref: React.ForwardedRef<React.ComponentRef<typeof Root>>) {
  const {
    as,
    icon,
    label,
    context,
    ...rest
  } = props;
  const Component = as ?? Root;
  return <Component role="button" ref={ref} className={"c8yc6y"} {...rest}>
      <div className={"dqzmx2q"}>
        {icon}
      </div>
      <div className={"dp5wq1v"}>
        <div>{label}</div>
        {context && <div className={"du5k8uq"}>
            {context}
          </div>}
      </div>
    </Component>;
}
export default React.forwardRef(SearchBoxListItem);

require("./SearchBoxListItem.linaria.module.css!=!../../../../node_modules/.pnpm/@linaria+webpack5-loader@5.0.4_webpack@5.93.0/node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js?cacheProvider=!./SearchBoxListItem.tsx");