import { useContext } from "timvir/core";
import { useCombobox } from "downshift";
import { cx } from "@linaria/core";
import * as React from "react";
import { SearchBoxInput } from "../../SearchBoxInput";
import { SearchBoxListItem } from "../../SearchBoxListItem";
import Highlight from "./Highlight";

/**
 * The underlying DOM element which is rendered by this component.
 */
const Root = "div";
interface Props extends React.ComponentPropsWithoutRef<typeof Root> {
  onClose?: (ev: React.SyntheticEvent<HTMLElement>) => void;
  q: (query: string) => Promise<{
    totalCount: number;
    edges: Array<{
      node: {
        path: string;
        label: string;
        context?: string;
      };
    }>;
  }>;
}
function Dialog(props: Props, ref: React.ForwardedRef<React.ComponentRef<typeof Root>>) {
  const {
    onClose,
    q,
    className,
    ...rest
  } = props;
  const {
    location,
    Link
  } = useContext();
  const [value, setValue] = React.useState("");
  const [result, setResult] = React.useState<undefined | {
    totalCount: number;
    edges: Array<{
      node: {
        path: string;
        label: string;
        context?: string;
      };
    }>;
  }>(undefined);
  React.useEffect(() => {
    q(value).then(result => {
      setResult(result);
    });
  }, [value, q, setResult]);
  const items = result?.edges ?? [];
  const {
    getMenuProps,
    getInputProps,
    /* highlightedIndex, */getItemProps,
    closeMenu
  } = useCombobox({
    defaultHighlightedIndex: 0,
    items,
    itemToString: item => item ? item.node.label : "",
    onInputValueChange: ({
      inputValue
    }) => {
      setValue(inputValue ?? "");
    },
    onSelectedItemChange: ({
      selectedItem
    }) => {
      if (selectedItem) {
        location.push(selectedItem.node.path);
      }
      closeMenu();
    }
  });
  return <Root ref={ref} {...rest} className={cx(className, "r5ea8qd")}>
      <div className={"dp0smwa"}>
        <div className={"d1a28btb"}>
          <div className={"d1c5pom9"} onClick={onClose} />

          <div className={"djje8s3"}>
            <SearchBoxInput {...getInputProps()} />
            <main {...getMenuProps()} className={"m1mgb53c"}>
              {items.map((item, index) => <SearchBoxListItem key={index} as={Link} href={item.node.path} {...getItemProps({
              item,
              index,
              onClick: onClose
            })} icon={<svg x="0px" y="0px" width="16px" height="16px" viewBox="0 0 16 16">
                      <g fill="none" stroke="#444" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit={10}>
                        <path data-color="color-2" d="M4.5 11.5L11.5 11.5" />
                        <path data-color="color-2" d="M4.5 8.5L11.5 8.5" />
                        <path data-color="color-2" d="M4.5 5.5L6.5 5.5" />
                        <path d="M9.5 0.5L1.5 0.5 1.5 15.5 14.5 15.5 14.5 5.5z" />
                        <path d="M9.5 0.5L9.5 5.5 14.5 5.5" />
                      </g>
                    </svg>} label={<Highlight string={item.node.path} query={value} />} context={item.node.context} style={{
              // background: highlightedIndex === index ? "rgba(0, 0, 0, 0.05)" : undefined,
            }} />)}
            </main>
          </div>
        </div>
      </div>
    </Root>;
}
export default React.forwardRef(Dialog);

require("./Dialog.linaria.module.css!=!../../../../../node_modules/.pnpm/@linaria+webpack5-loader@5.0.4_webpack@5.93.0/node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js?cacheProvider=!./Dialog.tsx");